import { render, staticRenderFns } from "./EditIssue.vue?vue&type=template&id=90462ba8&scoped=true&"
import script from "./EditIssue.vue?vue&type=script&lang=js&"
export * from "./EditIssue.vue?vue&type=script&lang=js&"
import style0 from "./EditIssue.vue?vue&type=style&index=0&id=90462ba8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90462ba8",
  null
  
)

export default component.exports