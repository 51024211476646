<template>
  <b-modal
    v-model="dialogState"
    size="lg"
    scrollable
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    @show="initForm"
  >
    <template v-slot:modal-title>
      <h5 class="mb-0">
        <span class="text-secondary">{{ $t("page.issue.addComment") }}</span>
      </h5>
    </template>
    <template v-slot:default>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <b-form>
          <file-uploader
            ref="issuePhotos"
            :multiple="true"
            @setFiles="addPhotos"
          />
          <form-textarea
            v-model="comment"
            label="comment"
            veeName="comment"
            :veeRules="{ required: true }"
            rows="6"
          />
          <div class="font-weight-bold mb-3">
            {{ $t("page.issue.addPhotos") }}
          </div>
          <b-row class="no-gutters image-container">
            <img
              class="clickable mr-2"
              style="width: 60px"
              src="./../../assets/add-photo.svg"
              @click="openUploadDialog"
            />
            <div
              v-for="(photo, index) in photos"
              :key="index"
              class="image-wrapper mr-3"
            >
              <b-img :src="photo.src" class="image-item" />
              <b-icon
                class="custom-badge clickable"
                icon="trash-fill"
                color="white"
                @click="removePhoto(photo)"
              />
            </div>
          </b-row>
        </b-form>
        <hr class="my-3 mx-n3" />
        <b-row class="no-gutters justify-content-end">
          <b-button variant="outline-dark" class="mr-3" @click="closeModal">
            {{ $t("button.cancel") }}
          </b-button>
          <b-button
            class="text-white"
            variant="primary"
            :disabled="invalid"
            @click="createComment"
          >
            {{ $t("button.insert") }}
          </b-button>
        </b-row>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import FormTextarea from "../Base/BaseFormTextarea";
import FileUploader from "../Base/BaseFileUploader";
import uniqueId from "lodash/uniqueId";
export default {
  components: {
    FormTextarea,
    FileUploader
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    issue: {
      type: Object,
      defalut: () => {}
    },
    callbackAfterInsert: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      comment: "",
      photos: []
    };
  },
  computed: {
    dialogState: {
      get() {
        return this.active;
      },
      set(value) {
        this.$emit("update:active", value);
      }
    }
  },
  methods: {
    closeModal() {
      this.dialogState = false;
    },
    initForm() {
      this.comment = "";
      this.photos = [];
    },
    openUploadDialog() {
      this.$refs.issuePhotos.openSelectFileWindow();
    },
    addPhotos(files) {
      if (!files) return;
      // TBD: check the type of the uploaded files
      // if (!this.isImage(files[0].type)) {
      //   this.$store.commit("alert/SET_ALERT", {
      //     messageText: "invalidFileFormat.text"
      //   });
      //   return;
      // }
      files.forEach(file => {
        this.photos.unshift({
          _id: uniqueId("local_"),
          file: file,
          src: URL.createObjectURL(file)
        });
      });
    },
    removePhoto(val) {
      const index = this.photos
        .map(item => {
          return item._id;
        })
        .indexOf(val._id);
      this.photos.splice(index, 1);
    },
    async createComment() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("issue/createComment", {
          condominiumId: this.issue.condominiumId._id,
          issueId: this.issue._id,
          issueInfo: this.getCommentInfo()
        });
        await this.$store.dispatch("issue/retrieveIssue", {
          condominiumId: this.issue.condominiumId._id,
          issueId: this.issue._id
        });
        await this.callbackAfterInsert();
        this.closeModal();
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    getCommentInfo() {
      const commentInfo = {
        body: this.comment
      };
      if (this.photos.length > 0) {
        const photoFiles = [];
        this.photos.forEach(item => {
          photoFiles.unshift(item.file);
        });
        if (photoFiles.length > 1) {
          commentInfo.photos = photoFiles;
        } else {
          commentInfo["photos[]"] = photoFiles;
        }
      }

      return commentInfo;
    }
  }
};
</script>

<style lang="scss" scoped>
.image-container {
  overflow-x: auto;
  overflow-y: visible;
  display: flex;
  flex-wrap: nowrap;
}
.image-wrapper {
  position: relative;
}
.image-item {
  border-radius: 10px;
  width: 60px;
  height: 100%;
}
.custom-badge {
  position: absolute;
  font-size: 20px;
  padding: 4px;
  bottom: 0px;
  right: -10px;
  background: var(--red);
  border-radius: 25px;
}
</style>
